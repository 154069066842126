import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Header, Container, Grid, Image, Button, Icon, Segment } from 'semantic-ui-react';
import { getCart, cartProductPropType } from './reducer';
import { setQuantity, removeProduct } from './actions';
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';

import './styles.scss';
import config from "../../config/config";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.increaseItemQuantity = this.increaseItemQuantity.bind(this);
    this.reduceItemQuantity = this.reduceItemQuantity.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  componentDidMount() {
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }
  }

  getTotalPrice() {
    const total = _.sumBy(this.props.cart, item => (Number(item.quantity) * Number(item.price)));
    return Math.round(total * 100) / 100;
  }

  increaseItemQuantity(id, variationId, quantity) {
    const { dispatch } = this.props;
    dispatch(setQuantity(id, variationId, quantity + 1));
  }

  /**
   * Decrease product quantity
   */
  reduceItemQuantity(id, variationId, quantity) {
    const { dispatch } = this.props;

    if (quantity - 1 === 0) {
      dispatch(removeProduct(id, variationId));
      return;
    }

    dispatch(setQuantity(id, variationId, quantity - 1));
  }

  removeItem(id, variationId) {
    const { dispatch } = this.props;
    dispatch(removeProduct(id, variationId));
  }

  render() {
    return (
      <div>
        <Header className='CartHeader' equal textAlign="center">Twój koszyk</Header>
        <Container>
          <Grid
            columns='equal'
            className='CartGrid'
            textAlign='center'
            divided='vertically'
            stackable
          >
            <Grid.Row className='CartGridHeader'>
              <Grid.Column>produkt</Grid.Column>
              <Grid.Column>ilosc</Grid.Column>
              <Grid.Column>cena</Grid.Column>
            </Grid.Row>
            {_.isEmpty(this.props.cart) &&
              <Grid.Row>
                <Grid.Column textAlign="center">Koszyk jest pusty</Grid.Column>
              </Grid.Row>
            }
            {this.props.cart.map(product => (
              <Grid.Row key={product.id + product.name}>
                <Grid.Column textAlign='left'>
                  <Image className='CartImage' src={product.image} verticalAlign='middle'/>
                  <span className='CartName'>{product.name}</span>
                </Grid.Column>
                <Grid.Column verticalAlign='middle'>
                  <span className='CartQuantity'>{product.quantity}</span>
                  <Button
                    className='CartButton'
                    circular
                    icon
                    size='huge'
                    onClick={() => this.reduceItemQuantity(product.id, product.variationId, product.quantity)}
                  >
                    <Icon name='minus circle'/>
                  </Button>
                  <Button
                    className='CartButton'
                    circular
                    icon
                    size='huge'
                    onClick={() => this.increaseItemQuantity(product.id, product.variationId, product.quantity)}
                  >
                    <Icon name='plus circle'/>
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign='right' verticalAlign='middle'>
                  <span className='CartPrice' dangerouslySetInnerHTML={{__html: product.price + ' ' + config.CURRENCY,}}/>
                  <Button
                    className='CartRemoveButton CartButton'
                    icon
                    onClick={() => this.removeItem(product.id, product.variationId)}
                    size='huge'
                  >
                    <Icon name='remove'/>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            ))}
            <Grid.Row>
              <Grid.Column/>
              <Grid.Column/>
              <Grid.Column/>
              {!_.isEmpty(this.props.cart) &&
                <Grid.Column>
                  <Segment
                    className='CartTotalPrice'
                    dangerouslySetInnerHTML={{__html: this.getTotalPrice() + ' ' + config.CURRENCY,}}
                    attached='top'/>
                  <Link to={'/checkout'} className='CartCheckoutLink'>
                    <Button attached='bottom'>Potwierdź zakup
                    </Button>
                  </Link>
                </Grid.Column>
              }
            </Grid.Row>
          </Grid>
        </Container>
      </div>
      );
  }
}

Cart.propTypes = {
  cart: PropTypes.arrayOf(cartProductPropType).isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  cart: getCart(state.cart),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ closeSearch }, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart);
