import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { variationPropType } from './reducer';

import './VariationsDropdown.scss';

class VariationsDropdown extends Component {
  constructor(props) {
    super(props);

    // Get all option names so that they can be saved to the state
    const optionNames = this.props.variations[0].attributes.map(attribute => attribute.name);

    // Get all attribute combinations so that they can be saved to the state
    const attributes = this.props.variations.map(variation => variation.attributes);

    const options = {};

    // initialize options with the corresponding values
    optionNames.forEach(function (optionName) {
      options[optionName] = this.getOptionValues(optionName);
    }, this);

    this.state = {
      options,
      optionNames,
      attributes,
      selected: {}
    };
  }

  // function that returns all the possible initial values for an option
  getOptionValues(optionName) {
    const values = _.uniq(
      this.props.variations.map((variation) => {
        const element = variation.attributes.find(attribute => attribute.name === optionName);

        return element.option;
      }),
    );
    return values;
  }

  handleLocalSelection(key, value) {
    this.setState({
      selected: {
        ...this.state.selected,
        [key]:value
      }
    }, this.props.handleSelect(key, value));
  }

  render() {
    // get options and values in the same order provided by a  for...in loop
    const options = Object.keys(this.state.options);
    const valuesArray = Object.values(this.state.options);

    // build a values object in the format that the semantic ui dropdown component expects
    const dropdownValues = valuesArray.map(valueArray => valueArray.map(value => ({ value, text: value })));

    const dropdowns = options.map((name, index) => (
      <Table key={name+index}>
        <Table.Cell>
          {name}
        </Table.Cell>
        <Table.Cell className='VariationsColorWrapper'>
          {dropdownValues[index].map(value =>
            <div
              key={value.value}
              className={`VariationsColor ${value.value === this.state.selected[name] ? 'active': ''}`}
              style={{backgroundColor: value.text.split('-')[1]}}
              onClick={() => this.handleLocalSelection(name, value.value)}
            />
          )}
        </Table.Cell>
      </Table>
        //<Dropdown placeholder={name} fluid selection options={dropdownValues[index]} onChange={(event, data) => this.props.handleSelect(data.placeholder, data.value)} />{' '}
    ));

    return (<Table className='VariationsTable' celled><Table.Body>{dropdowns}</Table.Body></Table>);
  }
}

VariationsDropdown.propTypes = {
  variations: PropTypes.arrayOf(variationPropType).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default VariationsDropdown;
