import React from 'react';

import ContactSegment from "../../components/ContactSegment";
import SectionHeader from "../../components/SectionHeader";

import img from "./kontakt.png";

const Contact = () => {
  return (
    <React.Fragment>
      <SectionHeader
        text='Kontakt'
        img={img}
      />
      <ContactSegment/>
    </React.Fragment>
  );
};

export default Contact;
