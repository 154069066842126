import React, {Component} from 'react';
import { Loader, Image } from 'semantic-ui-react';

import image from './pas.jpg';
import travolta from './giphy.gif';

import SectionHeader from '../../components/SectionHeader';

import config from '../../config/config'
import {clearCart} from "../Cart/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import './Checkout.scss'

class Success extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false
    };

  }

  checkOrderStatus = async () => {
    return fetch(config.API_UPDATE_ORDER, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body:
        JSON.stringify({
          'session_id': this.props.match.params['sessionId'],
          'order_id': this.props.match.params['orderId']
        })
    }).then(res => res.json())
  }

  async componentDidMount() {
    const res = await this.checkOrderStatus();

    if (res && JSON.parse(res).code === 200) {
      this.props.dispatch(clearCart());

      this.setState({
        loading: false,
        success: true
      })
    } else {
      this.setState({
        loading: false,
        success: false
      })
    }
  }

  render() {
    return (
      <div className='SuccessWrapper'>
        {this.state.loading && <Loader active />}
        {this.state.success && !this.state.loading && <SectionHeader img={image} text="Dzięki! Twoje zamówienie do nas trafiło, dostaniesz e-mail jak tylko zostanie wysłane"/>}
        {!this.state.success && !this.state.loading && <Image className='Travolta' src={travolta}/>}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ clearCart }, dispatch));
}

export default connect(null, mapDispatchToProps)(Success);
