import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader, Segment } from 'semantic-ui-react';
import _ from 'lodash';

import { fetchPosts } from "../Blog/actions";
import { getPosts, getPostsFetching } from "../Blog/reducer";
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';

import SectionHeader from '../../components/SectionHeader';
import LatestPosts from "../../components/LatestPosts";

import './BlogPost.scss';

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.loadPosts = this.loadPosts.bind(this);

  }

  componentDidMount() {
    if (this.props.searchVisible)
      this.props.closeSearch();

    this.readPosts(1);
  }

  loadPosts() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating posts
      const nextPage = Math.round(this.props.posts.length / 20) + 1;
      this.readPosts(nextPage);
    }
  }

  readPosts(page) {
    const { dispatch } = this.props;
    dispatch(fetchPosts({ page, per_page: 20, hide_empty: true }));
  }

  render() {
    const { posts, match, loading } = this.props;

    const post = posts.find(
      obj => obj.id === Number(this.props.match.params.postId),
    );

    return (
      <div className='BlogPostWrapper'>
        {loading === 1 ?
          <Loader active />
        : !_.isNil(post) ?
          <div>
            <SectionHeader
            img={post.imgLarge}
            text={post.name}
            />
              <Segment basic className='BlogPost' dangerouslySetInnerHTML={{ __html: post.content}}/>
            <LatestPosts posts={posts.filter(post => post.id !== Number(match.params.postId))}/>
          </div>
        : <p>Post does not exist</p>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: getPostsFetching(state.posts),
  posts: getPosts(state.posts),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators({ fetchPosts, closeSearch }, dispatch),
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlogPost);
