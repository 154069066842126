import React, { Component } from 'react';
import { Segment, Grid, List, Icon } from 'semantic-ui-react';
import ContactForm from '../ContactForm';
import './ContactSegment.scss';

export default class ContactSegment extends Component {
  render() {
    return (
      <Segment basic className='ContactSegment'>
        <Grid stackable reversed='mobile'>
          <Grid.Column floated='left' width={6}>
            <ContactForm/>
          </Grid.Column>
          <Grid.Column floated='right' className='ContactDetails' width={4}>
            <h1>Kontakt</h1>
            <List>
              <List.Item>
                <a href='tel:513847359' target='_blank' rel="noopener noreferrer"><b>telefon</b> 513847359</a>
              </List.Item>
              <List.Item>
                <a href='mailto:przemyslaw@pipala.pl'><b>e-mail</b> przemyslaw@pipala.pl</a>
              </List.Item>
              <List.Item>
                <a href='https://www.facebook.com/pipalaleatherworks/' target='_blank' rel="noopener noreferrer">
                  <Icon link name='facebook f' />
                </a>
                <a href='https://www.instagram.com/pipala_leatherworks/' target='_blank' rel="noopener noreferrer">
                  <Icon link name='instagram' />
                </a>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Segment>
    )
  }
}
