import React from 'react';

import './Hamburger.scss';

const Hamburger = ({ open, handleClick }) => {
  return (
    <div onClick={handleClick} className={`${open ? 'open' : ''} Hamburger`}>
      <div className="HamburgerLine"/>
      <div className="HamburgerLine"/>
      <div className="HamburgerLine"/>
    </div>
  );
};

export default Hamburger;
