import React, { Component } from 'react';
import { Form, TextArea, Message } from 'semantic-ui-react';
import './ContactForm.scss';

export default class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    msg: '',
    submittedName: '',
    submittedEmail: '',
    submittedMsg: '',
    loading: false,
    message: '',
    error: false,
    errors: []
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  handleSubmit = () => {
    const { name, email, msg } = this.state;
    this.setState(
      {
        submittedName: name,
        submittedEmail: email,
        submittedMsg: msg,
        loading: true,
        message: '',
        error: false,
        errors: []
      }
    );


    (async () => {
      const emailBody = {
        'username': name,
        email,
        msg
      };
      const form = new FormData();

      for (const field in emailBody) {
        form.append(field, emailBody[field]);
      }


      const rawResponse = await fetch('https://pipala.pl/wp-json/contact-form-7/v1/contact-forms/551/feedback', {
        method: 'POST',
        body: form
      });
      const content = await rawResponse.json();

      if (content.status === 'mail_sent') {
        this.setState({
          loading: false,
          error: false,
          errors: [],
          message: content.message,
          name: '',
          email: '',
          msg: ''
        })
      } else if (content.status === 'validation_failed') {
        this.setState({
          loading: false,
          error: true,
          message: content.message,
          errors: content['invalid_fields'].map(({message, into}) => ({message, name: into.split('.')[into.split('.').length - 1]}))
        })
      }
    })();
  };

  render() {
    const { name, email, msg } = this.state;

    return (
      <Form
        loading={this.state.loading}
        className='ContactForm'
        onSubmit={this.handleSubmit}
      >
        <Form.Field>
          {this.state.errors.find(({name}) => name === 'name') ?
            <div
              className="ui pointing below prompt label"
              id="form-input-control-error-email-error-message"
              role="alert"
              aria-atomic="true"
            >
              {this.state.errors.find(({name}) => name === 'name').message}
            </div> : null
          }
          <input
            type="text"
            placeholder=' '
            name='name'
            value={name}
            onChange={this.handleChange}
          />
          <label>imię i nazwisko</label>
        </Form.Field>
        <Form.Field>
          {this.state.errors.find(({name}) => name === 'email') ?
            <div
              className="ui pointing below prompt label"
              id="form-input-control-error-email-error-message"
              role="alert"
              aria-atomic="true"
            >
              {this.state.errors.find(({name}) => name === 'email').message}
            </div> : null
          }
          <input
            type="email"
            placeholder=' '
            name='email'
            value={email}
            onChange={this.handleChange}
          />
          <label>adres e-mail</label>
        </Form.Field>
        <Form.Field>
          {this.state.errors.find(({name}) => name === 'msg') ?
            <div
              className="ui pointing below prompt label"
              id="form-input-control-error-email-error-message"
              role="alert"
              aria-atomic="true"
            >
              {this.state.errors.find(({name}) => name === 'msg').message}
            </div> : null
          }
          <TextArea
            rows={5}
            placeholder=' '
            name='msg'
            value={msg}
            onChange={this.handleChange}
          />
          <label>wiadomość</label>
        </Form.Field>
        <Form.Button fluid content='Wyślij' />
        {this.state.message &&
          <Message
            positive={!this.state.error}
            negative={this.state.error}
          >
            <p>{this.state.message}</p>
          </Message>
        }
      </Form>
    )
  }
}
