import React, { useEffect, useState } from 'react';
import { Loader } from "semantic-ui-react";

import './LazyImage.scss';

const LazyImage = ({ src, type, handleClick }) => {
  const [loaded, setLoaded] = useState(false);

  function loadedFunc() {
    setLoaded(true);
  }

  useEffect(() => {
    let img = new Image();
    if (src){
      img.src = src;
      img.addEventListener('load', () => loadedFunc())
    }

    return function cleanup() {
      img.removeEventListener('load', () => loadedFunc());
    }
  }, []);

  if (type === 'background') {
    return <div
      onClick={handleClick}
      className={loaded ? 'LazyDiv Loaded' : 'LazyDiv'}
      style={{backgroundImage: `url(${src})`}}>
      {!loaded && <Loader active/>}
    </div>
  } else {

  }

  return (
    <img
      src={src}
      onClick={handleClick}
      alt=''
      className={loaded ? 'LazyImage Loaded' : 'LazyImage'}
    />
  );
};

export default LazyImage;
