import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {Container, Grid, Loader} from 'semantic-ui-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fetchProducts } from '../Products/actions';
import { getCategories } from '../Categories/reducer';
import { getProducts, getProductsFetching, productPropType } from '../Products/reducer';
import ProductDetails from './ProductDetails';
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';
import {fetchCategories} from "../Categories/actions";
import {Link} from "react-router-dom";

class Product extends Component {
  constructor(props) {
    super(props);

    this.loadCategories = this.loadCategories.bind(this);
  }

  componentDidMount() {
    const { searchVisible } = this.props;
    this.readProduct();
    this.readCategories(1);

    if (searchVisible) {
      this.props.closeSearch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.productId !== prevProps.match.params.productId) {
      this.readProduct();
    }
  }

  loadCategories() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating categories
      const nextPage = Math.round(this.props.categories.length / 20) + 1;
      this.readCategories(nextPage);
    }
  }

  readCategories(page) {
    const { dispatch } = this.props;
    dispatch(fetchCategories({ page, per_page: 20, hide_empty: true }));
  }

  readProduct() {
    const { dispatch } = this.props;
    dispatch(fetchProducts({ id: this.props.match.params.productId }));
  }

  render() {
    if (this.props.loading === 1) {
      return (
        <div>
          <Loader active />
        </div>
      );
    }

    const product = this.props.products.find(
      obj => obj.id === Number(this.props.match.params.productId),
    );

    if (_.isNil(product)) {
      return <p>Product does not exist</p>;
    }

    return (
      <div>
        <div className='ProductsCategoriesHeader'>
          <Container>
            <Grid centered columns={5}>
              <Grid.Column>
                <Link to={`/category/x`}>wszystkie produkty</Link>
              </Grid.Column>
              {this.props.categories && this.props.categories.map(category =>
                <Grid.Column key={category.id + '-' + category.slug}>
                  <Link
                    className={Number(product.categories[0].id) === category.id ? 'active' : ''}
                    to={`/category/${category.id}`}>{category.name}
                  </Link>
                </Grid.Column>
              )}
            </Grid>
          </Container>
        </div>
        <ProductDetails product={product} />
      </div>
    )
  }
}

Product.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(productPropType).isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: getProductsFetching(state.products),
  products: getProducts(state.products),
  categories: getCategories(state.categories),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ fetchProducts, closeSearch, fetchCategories }, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product);
