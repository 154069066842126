import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Grid, List, Image } from 'semantic-ui-react';
import './Footer.scss';
import { fetchPosts } from '../../views/Blog/actions';
import { getPosts, getPostsFetching, getPostsHasMore } from '../../views/Blog/reducer';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import logoUE from './ue-ad.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.loadPosts = this.loadPosts.bind(this);
  }

  componentDidMount() {
    this.readPosts(1);
  }

  loadPosts() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating posts
      const nextPage = Math.round(this.props.posts.length / 20) + 1;
      this.readPosts(nextPage);
    }
  }

  readPosts(page) {
    const { dispatch } = this.props;
    dispatch(fetchPosts({ page, per_page: 20, hide_empty: true }));
  }

  render() {
    const { loading, posts, hasMore } = this.props;

    return (
      <div>
        <Segment basic className='Footer'>
          <Grid stackable reversed='mobile'>
            <Grid.Column floated='left' verticalAlign='bottom' width={5}>
            <span className='FooterText'>
            © 2020, Pipala Leatherworks<br/>
            Made by <a href='https://pawcza.codes'>pawcza</a>
            </span>
            </Grid.Column>
            <Grid.Column floated='right' width={5}>
              <List verticalAlign='middle'>
                <List.Item floated='right'>
                  <Link to='/categories'>sklep</Link>
                </List.Item>
                <List.Item>
                  <Link to='/about'>o mnie</Link>
                </List.Item>
                <List.Item>
                  <Link to='/blog'>blog</Link>
                </List.Item>
                <List.Item>
                  <Link to='/contact'>kontakt</Link>
                </List.Item>
                <List.Item>
                  <Link to={`/post/717`}>polityka prywatności</Link>
                </List.Item>
                <List.Item>
                  <Link to='/post/715'>regulamin</Link>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Segment>
        <Image centered src={logoUE}/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: getPostsFetching(state.posts),
  posts: getPosts(state.posts),
  hasMore: getPostsHasMore(state.posts)
});

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators({ fetchPosts }, dispatch),
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
