import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import config from '../config/config';
import LazyImage from './LazyImage';

import './ProductCard.scss';

class ProductCard extends Component {
  render() {
    return (
      <Card className='SingleProductList'>
        <Link to={'/product/' + this.props.id}>
          <div className='SingleProductListImage'>
            <LazyImage type='background' src={this.props.src}/>
          </div>
          <p>{this.props.name}</p>
          <span
            className='Price'
            dangerouslySetInnerHTML={{ __html: this.props.price + ' ' + config.CURRENCY }}
          />
        </Link>
      </Card>
    );
  }
}

ProductCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ProductCard;
