import React from 'react';
import { Segment } from "semantic-ui-react";

import './About.scss';
import SectionHeader from "../../components/SectionHeader";

import img from './about.png';

const About = () => {
  return (
    <div className='AboutPage'>
      <div>
        <SectionHeader
          img={img}
          text='O mnie'
        />
        <Segment basic className='BlogPost' dangerouslySetInnerHTML={
          { __html: `
            <p>
              <b>Cześć!</b>
              Nazywam się Przemysław Pipała i jestem założycielem pracowni Pipała Leatherworks. Z wykształcenia jestem technikiem informatykiem, więc możesz śmiało założyć, że wybór mojej życiowej ścieżki nie był oczywisty od najmłodszych lat. Niemniej, jesteśmy teraz tutaj, ponieważ w 2016 roku zacząłem swoją przygodę z tym niesamowitym rzemiosłem.
              Początki nie były łatwe, jednak dzięki cierpliwości, dokładności oraz ambicji, rozwijałem zarówno swoje umiejętności jak i warsztat, aby móc zaoferować Ci wyroby eleganckie, ponadczasowe i wykonane w całości ręcznie, z wielkim przywiązaniem do detali.
              Dzięki temu, że każdy produkt wykonywany jest od początku do końca specjalnie dla Ciebie, możliwa jest szeroka personalizacja i ingerencja w wyrób, wszystko po to, żeby spełnić Twoje oczekiwania.
              W mojej pracowni korzystam wyłącznie z wysokiej jakości skór z włoskich lub francuskich garbarni, nie są to produkty anonimowe, stoi za nimi prestiż danej garbarni, która, tak jak ja, jest dumna ze swoich wyrobów i nie wstydzi się swojej marki.
              Wszystkie portfele, paski, wizytowniki czy inne akcesoria, które wychodzą spod mojej ręki, wykonane są w całości ręcznie, za pomocą tradycyjnych metod, m.in. szycia rymarskiego, krawędzie skóry są kilkukrotnie szlifowane i malowane, aby nadać im pożądaną gładkość. Dzięki przyłożeniu dużej uwagi podczas każdego, pojedynczego etapu tworzenia, otrzymasz produkt nie tylko piękny, ale i długowieczny i ponadczasowy.
            </p>`
          }
          }/>
      </div>
    </div>
  );
};

export default About;
