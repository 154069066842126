import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Segment, Icon, Menu, Input, Form, Dropdown, Image, Transition } from 'semantic-ui-react';
import { getCategories } from '../../views/Categories/reducer';
import { openMenu, openSearch, closeSearch } from './actions';
import { isSearchVisible } from './reducer';
import { getCart } from '../../views/Cart/reducer';
import './NavBar.scss';
import logo from './logo.png';
import {fetchCategories} from "../../views/Categories/actions";
import Hamburger from "../Hamburger";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      toSearchPage: false,
      cart: 0,
      visible: true,
      menuVisible: false
    };
    this.loadCategories = this.loadCategories.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openSearch = this.openSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
   if (window.screen.width > 768) {
     this.setState({ menuVisible: true })
   }
  }

  componentDidUpdate() {
    if (this.state.cart !== this.getQuantity()) {
      this.toggleVisibility();
      this.setState({cart: this.getQuantity()})
    }
  }

  toggleVisibility = () =>
    this.setState((prevState) => ({ visible: !prevState.visible }));

  toggleMenu = () =>
    this.setState((prevState) => ({ menuVisible: !prevState.menuVisible }));

  closeMenu = () =>
    window.screen.width < 768 && this.setState({ menuVisible: false });

  loadCategories() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating categories
      const nextPage = Math.round(this.props.categories.length / 20) + 1;
      this.readCategories(nextPage);
    }
  }

  readCategories(page) {
    const { dispatch } = this.props;
    dispatch(fetchCategories({ page, per_page: 20, hide_empty: true }));
  }

  getQuantity() {
    const { cart } = this.props;
    return cart.reduce((quantity, item) => item.quantity + quantity, 0);
  }

  setSearch(e) {
    this.setState({ search: e.target.value });
  }

  /**
   * Handle search form submit.
   * Set state for redirecting to search page and close search box.
   */
  handleSubmit() {
    this.closeMenu();
    this.setState({ toSearchPage: this.state.search, search: '' });
  }

  /**
   * Open search box when icon is clicked.
   * Reset search input and redirect when the search is opened.
   */
  openSearch() {
    this.searchInput.focus();
    this.setState({ toSearchPage: false, search: '' }, () => this.props.openSearch());
  }

  closeSearch() {
    this.setState({ toSearchPage: false, search: '' }, () => this.props.closeSearch());
  }

  showSidebar(e) {
    e.stopPropagation();
    this.props.openMenu();
  }

  render() {
    const { searchVisible } = this.props;

    return (
      <Segment basic size="small" className='Menu'>
        <Segment basic className='MobileNavWrapper'>
          <Hamburger open={this.state.menuVisible} handleClick={this.toggleMenu}/>
          <Menu.Item fitted className='LogoWrapper mobile'>
            <Link to="/" onClick={this.closeMenu}>
              <Image
                className='Logo'
                src={logo}
                size='tiny'
              >
              </Image>
            </Link>
          </Menu.Item>
          <Menu.Item fitted className='CartIcon-Wrapper mobile'>
            <Link onClick={this.closeMenu} to="/cart">
              <Transition
                animation='pulse'
                duration='500'
                visible={this.state.visible}
              >
                <div>
                  {this.getQuantity() === 0 ? 0 : this.getQuantity()}
                </div>
              </Transition>
              <Icon
                className='CartIcon'
                name='shopping cart'
              />
            </Link>
          </Menu.Item>
        </Segment>
        <Transition animation='fade right' visible={this.state.menuVisible}>
          <Menu stackable fluid secondary>
            {/*<Menu.Item onClick={this.showSidebar} fitted>*/}
            {/*  <Icon name="content" size="large" onClick={this.showSidebar} className="shop-icon" />*/}
            {/*</Menu.Item>*/}
            <Menu.Item fitted className='LogoWrapper'>
              <Link to="/">
                <Image
                  className='Logo'
                  src={logo}
                  size='tiny'
                >
                </Image>
              </Link>
            </Menu.Item>
            <Dropdown
              text='sklep'
              pointing
              basic
              icon='chevron down'
              className='link item'
            >
              <Dropdown.Menu>
                {this.props.categories &&
                this.props.categories.map(category =>
                  <Dropdown.Item key={category.id + '-' + category.slug}>
                    <Link onClick={this.closeMenu} to={`/category/${category.id}`}>{category.name}</Link>
                  </Dropdown.Item>
                )
                }
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item active={this.props.location.pathname.split('/')[1] === 'about'}>
              <Link color='black' to='/about' onClick={this.closeMenu}>o mnie</Link>
            </Menu.Item>
            <Menu.Item active={this.props.location.pathname.split('/')[1] === 'blog'}>
              <Link to='/blog' onClick={this.closeMenu}>blog</Link>
            </Menu.Item>
            <Menu.Item active={this.props.location.pathname.split('/')[1] === 'contact'}>
              <Link to='/contact' onClick={this.closeMenu}>kontakt</Link>
            </Menu.Item>
            <Menu.Item position="right" fitted>
              <Form className={`SearchForm ${searchVisible && 'visible'}`} onSubmit={this.handleSubmit}>
                <Input
                  ref={inputEl => (this.searchInput = inputEl)}
                  name="search"
                  type="text"
                  className="search"
                  value={this.state.search}
                  onChange={this.setSearch}
                  onBlur={this.closeSearch}
                  placeholder="Szukaj..."
                  autoFocus
                />
                <Icon onClick={this.openSearch} className='searchIcon' name='search' color='black' size='large'/>
              </Form>
            </Menu.Item>
            <Menu.Item fitted className='CartIcon-Wrapper'>
              <Link to="/cart">
                <Transition
                  animation='pulse'
                  duration='500'
                  visible={this.state.visible}
                >
                  <div>
                    {this.getQuantity() === 0 ? 0 : this.getQuantity()}
                  </div>
                </Transition>
                <Icon
                  className='CartIcon'
                  name='shopping cart'
                />
              </Link>
            </Menu.Item>
          </Menu>
        </Transition>
        {this.state.toSearchPage && searchVisible ? <Redirect to={`/search/${this.state.toSearchPage}`} /> : null}
      </Segment>
    );
  }
}

NavBar.propTypes = {
  openMenu: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  closeSearch: PropTypes.func.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  cart: getCart(state.cart),
  searchVisible: isSearchVisible(state.navbar),
  categories: getCategories(state.categories)
});

export default withRouter(connect(
  mapStateToProps,
  { openMenu, openSearch, closeSearch },
)(NavBar));
