import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';

import {Form, Button, Container, Header, Grid, TextArea, Label, Segment, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import cogoToast from 'cogo-toast';
import config from '../../config/config.js';
import { getCart } from '../Cart/reducer';
import _ from "lodash";
import {isSearchVisible} from "../../components/NavBar/reducer";
import {bindActionCreators} from "redux";
import {closeSearch} from "../../components/NavBar/actions";
import {connect} from "react-redux";

import './Checkout.scss';

const stripePromise = loadStripe('pk_live_51H59EcLqxgU5RGI5zSfCGoiO4CIXRovU7igkxOQyvUWF0xC9RXlsm9xYcCZcOzFOsbvzoakOtRhqMuV6qFzjBRka00fp9OlpSw');
//const stripePromise = loadStripe('pk_test_51H59EcLqxgU5RGI58UItd1MN38BWVhsPsoZKKEVocBI4x7mKeXQf0hhUmQSxaUI80KNUbzNjYw2DA5x54ySIgl86007cVsG9wo');

class Checkout extends Component {
  state = {
    name: '',
    surname: '',
    street: '',
    house: '',
    postcode: '',
    town: '',
    phone: '',
    email: '',
    loading: false,
    isDimmerActive: false,
    delivery: '',
    deliveryCost: 0,
    additionalInfo: '',
    shippingMethods: null,
    voucherCode: '',
    voucherError: '',
    coupon: null
  };

  handleTncChange = () => this.setState((prevState) => ({ tncChecked: !prevState.tncChecked }))

  handleVoucherChange = ({ target }) => {
    this.setState({
      [target.name]: target.value.replace(/[\W_]+/g,"")
    });
  }

  handleChange = ({ target }) => {

    if (target.name === 'delivery') {
      this.setState({
        deliveryCost: this.state.shippingMethods.find(method => method.title === target.value).settings.cost.value
      })
    }

    if (target.value === 'Paczkomaty 24/7') {
      this.handleInpostModal()
    } else {
      this.setState({
        [target.name]: target.value
      });
    }
  };


  componentDidMount() {
    if (!this.state.shippingMethods) {
      this.fetchShippingMethods().then(res => {
        this.setState({
          shippingMethods: res
        })
      });
    }

    if (this.props.searchVisible) {
      this.props.closeSearch();
    }
  }

  handleInpostModal = () => {
    this.setState({ isDimmerActive: true });
    window.easyPack.modalMap(
      ({address_details}, modal ) => {
        modal.closeModal();

        const { street, building_number, post_code, city } = address_details;
        this.setState({
          street,
          house: building_number,
          postcode: post_code,
          town: city,
          delivery: 'Paczkomaty 24/7',
          isDimmerActive: false
        });
    }, { width: 500, height: 600 });
  };

  getTotalPrice() {
    const total = _.sumBy(this.props.cart, item => (Number(item.quantity) * Number(item.price)));
    return Math.round(total * 100) / 100;
  }

  fetchShippingMethods = async () => {
    return fetch(config.API_SHIPPING_METHODS_URL, {
      method: 'GET'
    }).then(res => res.json());
  };

  fetchCheckoutSession = async (wcId, deliveryCost) => {
    return fetch(config.API_STRIPE_SESSION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body:
        JSON.stringify({
          order_id: wcId,
          items:
            this.state.coupon && this.state.coupon.free_shipping ?
              this.props.cart.map(item =>
                ({
                  'price_data': {
                    'unit_amount': this.state.voucherAdded ?
                      this.state.coupon.discount_type === 'percent' ?
                        Math.ceil(((100 - this.state.coupon.amount) / 100) * Number(item.price) * 100) :
                        Math.ceil(((this.getTotalPrice() - this.state.coupon.amount) / this.getTotalPrice()) * Number(item.price) * 100) :
                      Number(item.price) * 100,
                    'currency': 'PLN',
                    'product_data': {
                      'name': item.name,
                      'images': [item.image]
                    }
                  },
                  'quantity': item.quantity
                })
              )
              : [
                ({
                  'price_data' : {
                    'unit_amount': Number(deliveryCost) * 100,
                    'currency': 'PLN',
                    'product_data': {
                      'name': this.state.delivery
                    }
                  },
                  'quantity': 1
                }),
                ...this.props.cart.map(item =>
                  ({
                    'price_data': {
                      'unit_amount': this.state.voucherAdded ?
                        this.state.coupon.discount_type === 'percent' ?
                          Math.ceil(((100 - this.state.coupon.amount) / 100) * Number(item.price) * 100) :
                          Math.ceil(((this.getTotalPrice() - this.state.coupon.amount) / this.getTotalPrice()) * Number(item.price) * 100) :
                        Number(item.price) * 100,
                      'currency': 'PLN',
                      'product_data': {
                        'name': item.name,
                        'images': [item.image]
                      }
                    },
                    'quantity': item.quantity
                  })
                )
              ]
        }),
    }).then((res) => res.json());
  };

  createWoocommerceOrder = async () => {
    const {name, surname, email, phone, street, house, town, postcode, additionalInfo, delivery} = this.state;

    return fetch(config.API_CREATE_ORDER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body:
        JSON.stringify({
          items: this.props.cart.map(item =>
            ({
              'variation_id': Number(item.variationId) || item.id,
              'product_id': item.id,
              'quantity': item.quantity,
              'meta_data':
                Object.entries(item.attributes).map((attribute, index) =>
                  ({
                    id: index,
                    key: attribute[0],
                    value: attribute[1]
                  })
                )
            })
          ),
          name,
          surname,
          email,
          phone,
          street,
          house,
          town,
          postcode,
          'meta_data': [
            {
              id: 0,
              key: 'Dodatkowe informacje',
              value: additionalInfo
            },
            {
              id: 1,
              key: 'Metoda dostawy',
              value: delivery
            }
          ],
          'coupon_lines': this.state.coupon ? [{code: this.state.coupon.code}] : []
        })
    }).then(res => res.json())
  };

  handleSubmit = async (event) => {
    const {name, surname, street, postcode, town, email, delivery, tncChecked } = this.state;

    if (name && surname && street && postcode && town && email && delivery && tncChecked) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        event.preventDefault();

        this.setState({loading: true});

        const woocommerceResponse = await this.createWoocommerceOrder();

        const stripeResponse = await this.fetchCheckoutSession(woocommerceResponse.id, this.state.coupon && this.state.coupon.free_shipping ? 0 : this.state.deliveryCost);
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;

        await stripe.redirectToCheckout({
          sessionId: JSON.parse(stripeResponse)['session_id'],
        });
      } else {
        cogoToast.warn('Podałeś nieprawidłowy adres e-mail');
      }

    } else {
      cogoToast.warn('Musisz wypełnić wszystkie dane');
    }
  };

  checkVoucher = async () => {
    return fetch(config.API_VOUCHER_CODE_URL + this.state.voucherCode, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res => res.json());
  }

  handleVoucherSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      voucherLoading: true
    })

    await this.checkVoucher().then(voucher => {
      this.setState({
        voucherLoading: false
      })

      if (voucher.data.status === 404 || voucher.length === 0) {
        cogoToast.warn('Niepoprawny kod!')
      } else {
        const codeObject = voucher[0];

        if (new Date(codeObject.date_expires) < Date.now() || (codeObject.usage_limit && codeObject.usage_count > codeObject.usage_limit)) {
          cogoToast.warn('Podany kod nie jest już aktualny!')
        } else {
          this.setState({voucherAdded: true, coupon: codeObject})
          cogoToast.success(`Promocja o wartości: -${codeObject.amount}${codeObject.discount_type === 'percent'?'%':'zł'} ${codeObject.free_shipping ? ' oraz darmowa dostawa ': ''}`);
        }
      }
    });

  }

  render() {
    const {
      name,
      surname,
      street,
      house,
      postcode,
      town,
      phone,
      email,
      delivery,
      additionalInfo,
      isDimmerActive,
      voucherCode
    } = this.state;

    return (
      <Dimmer.Dimmable as={Container} dimmed={isDimmerActive}>
        <Dimmer active={isDimmerActive} inverted onClickOutside={() => this.setState({isDimmerActive: false})} />
        <Form className='CheckoutForm' loading={this.state.loading}>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8} className='CheckoutInputs'>
                <Header>Dane kontaktowe</Header>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='name'
                    value={name}
                    required
                    onChange={this.handleChange}
                  />
                  <label>Imię*</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='surname'
                    value={surname}
                    required
                    onChange={this.handleChange}
                  />
                  <label>Nazwisko*</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="email"
                    placeholder=' '
                    name='email'
                    required
                    value={email}
                    onChange={this.handleChange}
                  />
                  <label>Adres E-mail*</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='phone'
                    value={phone}
                    onChange={this.handleChange}
                  />
                  <label>Nr. Telefonu</label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column mobile={16} computer={8} className='CheckoutInputs'>
                <Header>Dane do wysyłki</Header>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='street'
                    required
                    disabled={delivery === 'Paczkomaty 24/7'}
                    value={street}
                    onChange={this.handleChange}
                  />
                  <label>Ulica*</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='house'
                    value={house}
                    disabled={delivery === 'Paczkomaty 24/7'}
                    onChange={this.handleChange}
                  />
                  <label>Numer domu</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='postcode'
                    value={postcode}
                    disabled={delivery === 'Paczkomaty 24/7'}
                    onChange={this.handleChange}
                  />
                  <label>Kod Pocztowy*</label>
                </Form.Field>
                <Form.Field>
                  <input
                    type="text"
                    placeholder=' '
                    name='town'
                    value={town}
                    disabled={delivery === 'Paczkomaty 24/7'}
                    onChange={this.handleChange}
                  />
                  <label>Miasto*</label>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16} className='CheckoutRadios'>
                <Header>Sposób dostawy</Header>
                {this.state.shippingMethods ?
                  this.state.shippingMethods.map((method, index) =>
                    <Form.Field
                      key={method.title + index}
                      name='delivery'
                      control='input'
                      type='radio'
                      label={<Label>{method.title}<span className={this.state.coupon && this.state.coupon.free_shipping ? 'FreeDelivery' : ''}><p>{method.settings.cost.value}</p><span>{this.state.coupon && this.state.coupon.free_shipping ? ' darmowa': ''}</span></span></Label>}
                      value={method.title}
                      checked={delivery === method.title}
                      placeholder={method.title}
                      onChange={this.handleChange}
                    />
                  ) : <Loader active/>
                }
              </Grid.Column>
              <Grid.Column mobile={16} computer={8} className='CheckoutAdditional'>
                <Header>Dodatkowe informacje</Header>
                <TextArea name='additionalInfo' value={additionalInfo} onChange={this.handleChange} className='CheckoutTextarea'/>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16} className='CheckoutRadios'>
                  <Checkbox
                    className='CheckoutTncCheckbox'
                    name='tnc'
                    toggle
                    onChange={this.handleTncChange}
                    checked={this.state.tncChecked}
                    label={<label>* Zapoznałem się z treścią oraz akceptuję postanowienia <Link to='/post/715'>regulaminu</Link> oraz <Link to='/post/717'>Polityki Prywatności</Link> sklepu</label>}
                  />
              </Grid.Column>
              <Grid.Column mobile={16} computer={8} className='CheckoutAdditional'>
                <Form onSubmit={this.handleVoucherSubmit} loading={this.state.voucherLoading}>
                  <Form.Field>
                    <input
                      disabled={this.state.voucherLoading || this.state.voucherAdded}
                      type="text"
                      placeholder='Kupon promocyjny'
                      name='voucherCode'
                      value={voucherCode}
                      onChange={this.handleVoucherChange}
                      className='CheckoutVoucherCode'
                    />
                    <Button
                      disabled={this.state.voucherAdded}
                      onSubmit={this.handleVoucherSubmit}
                      color='black'
                      icon='check'
                      className='CheckoutVoucherButton'/>
                  </Form.Field>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column/>
              <Grid.Column/>
              <Grid.Column/>
              <Grid.Column mobile={16} computer={4} textAlign='right'>
                <Segment
                  className='CheckoutTotalPrice'
                  dangerouslySetInnerHTML={{__html: `${this.getTotalPrice()}${this.state.voucherAdded ? ' - ' + this.state.coupon.amount : ''}${this.state.voucherAdded && this.state.coupon.discount_type === 'percent' ? '%' : ''}${this.state.deliveryCost !== 0 ? ' + ' + (this.state.coupon && this.state.coupon.free_shipping ? 0 : this.state.deliveryCost) : ''} ${config.CURRENCY}`}}
                  attached='top'/>
                <Button
                  onClick={this.handleSubmit}
                  className='CheckoutButton'
                  type='submit'
                  disabled={!stripePromise}
                  attached='bottom'>Potwierdź zakup
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Dimmer.Dimmable>
    )
  }
}

const mapStateToProps = state => ({
  cart: getCart(state.cart),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ closeSearch }, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
