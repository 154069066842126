import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import Swiper from 'react-id-swiper';
import FsLightBox from 'fslightbox-react';
import {Header, Card, Icon, Button, Grid, Rail, Sticky, Ref, Segment, Label, Image } from 'semantic-ui-react';
import LazyImage from '../../components/LazyImage';
import { productPropType } from '../Products/reducer';
import { addProduct } from '../Cart/actions';
import Variations from '../../components/Variations';
import Attributes from '../../components/Attributes';

import './styles.scss';
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss';

class ProductDetails extends Component {
  contextRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      selections: {},
      variationId: null,
      isOpen: false,
      attributes: {},
      toggler: false,
      productIndex: 0
    };

    this.receiveSelections = this.receiveSelections.bind(this);
    this.receiveAttributes = this.receiveAttributes.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  /**
   * Modify component's state when a variation is selected.
   * @param {Object} selections
   * @param {Number} variationId
   * @param {String} variationImage
   */
  receiveSelections(selections, variationId, variationImage) {
    this.setState({ selections, variationId, variationImage });
  }

  receiveAttributes(name, value) {
    this.setState({
      attributes: {
        ...this.state.attributes,
        [name]: value
      }
    })
  }

  /**
   * Add product to cart.
   * Display a warning if the product has variations and attributes were not selected.
   */
  addItem() {
    if (Object.values(this.state.selections).length + Object.values(this.state.attributes).length < this.props.product.attributes.length) {
      cogoToast.warn('Musisz wybrać wszystkie opcje');
      return;
    }

    const { dispatch } = this.props;
    const product = this.props.product;

    dispatch(
      addProduct(
        product.id,
        product.name,
        product.price,
        product.images[0].src,
        this.state.variationId,
        this.state.selections,
        this.state.attributes
      ),
    );

    cogoToast.success(product.name + ' został dodany do koszyka.');
  }

  handleLightbox(index) {
    this.setState(prevState => ({
      toggler: !prevState.toggler,
      productIndex: index
    }))
  }

  render() {
    return (
      <Grid container stretched columns={2} stackable className='ProductOuterGrid'>
        <Grid.Column>
          <Ref innerRef={this.contextRef}>
            <Segment basic>
              <Segment basic className='ProductSwiper'>
                <Swiper>
                  <Image src={this.state.variationImage || this.props.product.images[0].src}/>
                  {this.props.product.images.slice(1).map(image =>
                    <Image src={image.src}/>
                  )}
                </Swiper>
              </Segment>
              <Grid className='ProductImageGrid' verticalAlign='top' stretched>
                <Grid.Column className='ProductImageColumn' width={16}>
                  <LazyImage handleClick={()=>this.handleLightbox(0)} src={ this.state.variationImage || this.props.product.images[0].src }/>
                </Grid.Column>
                {this.props.product.images.slice(1).map((image, index) =>
                  <Grid.Column key={image.src + index} width={8} className='ProductImageColumn'>
                    <LazyImage handleClick={()=>this.handleLightbox(index)} src={image.src}/>
                  </Grid.Column>
                )}
              </Grid>
              <Rail position='right' className='ProductRail'>
                <Sticky context={this.contextRef}>
                  <Grid.Column width={10}>
                    <Header className="ProductTitle">
                      {this.props.product.name}
                    </Header>

                    {this.props.product.variations.length === 0 ? null : (
                      <Variations
                        sendSelections={this.receiveSelections}
                        productId={this.props.product.id}
                        variationIds={this.props.product.variations}
                      />
                    )}

                    {this.props.product.attributes.length === 0 ? null : (
                      <Attributes
                        attributes={this.props.product.attributes.filter(attribute => !attribute.variation)}
                        sendAttributes={this.receiveAttributes}
                      />
                    )}

                    {this.props.product.backorders_allowed || this.props.product.stock_quantity > 0 ? (
                      <div className='ProductAddButtonWrapper'>
                        <Button
                          animated='fade'
                          onClick={this.addItem}
                          className='ProductAddButton'
                        >
                          <Button.Content visible>Dodaj do koszyka</Button.Content>
                          <Button.Content hidden>
                            <Icon name='cart arrow down'/>
                          </Button.Content>
                        </Button>
                        <Label className='ProductAddLabel'>{this.props.product.price + ',-'}</Label>
                      </div>
                    ) : null}

                    {this.props.product.price ?
                      (<Card.Content>
                        <div />
                      </Card.Content>) : null}
                    <div className='ProductDescription' dangerouslySetInnerHTML={{ __html: this.props.product.description }} />
                  </Grid.Column>
                </Sticky>
              </Rail>
              <Grid.Column width={16} className='ProductMobileColumn'>
                <Header className="ProductTitle">
                  {this.props.product.name}
                </Header>

                {this.props.product.variations.length === 0 ? null : (
                  <Variations
                    sendSelections={this.receiveSelections}
                    productId={this.props.product.id}
                    variationIds={this.props.product.variations}
                  />
                )}

                {this.props.product.attributes.length === 0 ? null : (
                  <Attributes
                    attributes={this.props.product.attributes.filter(attribute => !attribute.variation)}
                    sendAttributes={this.receiveAttributes}
                  />
                )}

                {this.props.product.backorders_allowed || this.props.product.stock_quantity > 0 ? (
                  <div className='ProductAddButtonWrapper'>
                    <Button
                      animated='fade'
                      onClick={this.addItem}
                      className='ProductAddButton'
                    >
                      <Button.Content visible>Dodaj do koszyka</Button.Content>
                      <Button.Content hidden>
                        <Icon name='cart arrow down'/>
                      </Button.Content>
                    </Button>
                    <Label className='ProductAddLabel'>{this.props.product.price + ',-'}</Label>
                  </div>
                ) : null}

                {this.props.product.price ?
                  (<Card.Content>
                    <div />
                  </Card.Content>) : null}
                <div className='ProductDescription' dangerouslySetInnerHTML={{ __html: this.props.product.description }} />
              </Grid.Column>
            </Segment>
          </Ref>
        </Grid.Column>
        <FsLightBox
          toggler={this.state.toggler}
          sources={this.props.product.images.map(image => image.src)}
          slide={this.state.productIndex}
          type="image"
          />
      </Grid>
    );
  }
}

ProductDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  product: productPropType.isRequired,
};

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ addProduct }, dispatch));
}

export default connect(null, mapDispatchToProps)(ProductDetails);
