import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import LazyImage from "../LazyImage";

import './SectionHeader.scss';

class Index extends Component {
  render() {
    const { img, rtl, text, children } = this.props;
    return (
      <Segment basic className='SectionHeader'>
        <Grid stackable>
          {rtl ? (
              <Grid.Row stretched>
                <Grid.Column className='TextColumn' width={9}>
                  <p>{text}</p>
                </Grid.Column>
                <Grid.Column floated='right' className='ImageColumn' width={7}>
                  <LazyImage src={img} type='background'/>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column className='ImageColumn' width={7}>
                  <LazyImage src={img} type='background'/>
                </Grid.Column>
                <Grid.Column className='TextColumn' width={9}>
                  <p>{text}</p>
                </Grid.Column>
              </Grid.Row>
            )}
        </Grid>
      </Segment>
    );
  }
}

export default Index;
