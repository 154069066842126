import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ProductCard from './ProductCard';
import { productPropType } from '../views/Products/reducer';

import './ProductsList.scss';

class ProductsList extends Component {
  render() {
    const list = this.props.products.map(element => {
      return (
        <Grid.Column key={element.id}>
          <ProductCard
            id={element.id}
            src={element.images && element.images[0].src}
            name={element.name}
            price={element.price}
            categories={element.categories}
          />
        </Grid.Column>
    )});

    return (
      <Container>
        <Grid className='ProductsListGrid' doubling columns={4}>
          {list}
        </Grid>
      </Container>
    );
  }
}

ProductsList.propTypes = {
  products: PropTypes.arrayOf(productPropType).isRequired,
};

export default ProductsList;
