import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader, Container } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import { fetchPosts } from './actions';
import { getPosts, getPostsFetching, postPropType, getPostsHasMore } from './reducer';
import LatestPosts from "../../components/LatestPosts";
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';

import './Blog.scss';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.loadPosts = this.loadPosts.bind(this);
  }

  componentDidMount() {
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }

    this.readPosts(1);
  }

  loadPosts() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating posts
      const nextPage = Math.round(this.props.posts.length / 20) + 1;
      this.readPosts(nextPage);
    }
  }

  readPosts(page) {
    const { dispatch } = this.props;
    dispatch(fetchPosts({ page, per_page: 20, hide_empty: true }));
  }

  render() {
    const { loading, posts, hasMore } = this.props;

    if (loading === 1 && posts.length === 0) {
      return (
        <div className='BlogWrapper'>
          <Loader active />
        </div>
      );
    }

    if (posts.length === 0) {
      return (
        <Container>
          <p>No posts found.</p>
        </Container>
      );
    }

    const items = _.orderBy(posts, ['name'], ['asc']);

    return (
      <Container>
        <InfiniteScroll
          dataLength={items.length}
          next={this.loadPosts}
          hasMore={hasMore}
        >
          <LatestPosts posts={items} />
        </InfiniteScroll>
      </Container>
    );
  }
}

Posts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
  posts: PropTypes.arrayOf(postPropType).isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: getPostsFetching(state.posts),
  posts: getPosts(state.posts),
  hasMore: getPostsHasMore(state.posts),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators({ fetchPosts, closeSearch }, dispatch),
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Posts);
