import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Loader, Container, Grid} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { fetchCategories } from '../Categories/actions';
import { getCategories } from '../Categories/reducer';
import { fetchProducts } from './actions';
import { getProductsFetching, getProducts, productPropType, getProductsHasMore } from './reducer';
import ProductsList from '../../components/ProductsList';
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';

import './styles.scss';

class Products extends Component {
  constructor(props) {
    super(props);
    this.loadProducts = this.loadProducts.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
  }

  componentDidMount() {
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }

    this.readProducts(1, true);
    this.readCategories(1);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.params.categId !== prevProps.match.params.categId) {
      this.readProducts(1, true);
    }
  }

  loadCategories() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating categories
      const nextPage = Math.round(this.props.categories.length / 20) + 1;
      this.readCategories(nextPage);
    }
  }

  readCategories(page) {
    const { dispatch } = this.props;
    dispatch(fetchCategories({ page, per_page: 20, hide_empty: true }));
  }

  getCategoryName(categories) {
    if (this.props.match.params.categId !== 'x')
      return categories.find(category =>
        Number(category.id) === Number(this.props.match.params.categId)).name;
  }

  loadProducts() {
    if (this.props.hasMore) {
      // 10 is the default per_page number used for paginating products
      const nextPage = Math.round(this.props.products.length / 10) + 1;
      this.readProducts(nextPage);
    }
  }

  readProducts(page, skip) {
    const { dispatch } = this.props;
    dispatch(fetchProducts({
      category: this.props.match.params.categId,
      page,
      order: 'asc',
      orderby: 'title',
      per_page: 8
    }, skip));
  }

  render() {
    const { loading, products, hasMore } = this.props;
    return (
      <div>
        <div className='ProductsCategoriesHeader'>
          <Container>
            <Grid centered columns={5}>
              <Grid.Column>
                <Link
                  className={this.props.match.params.categId === 'x' ? 'active' : ''}
                  to={`/category/x`}>wszystkie produkty
                </Link>
              </Grid.Column>
              {this.props.categories && this.props.categories.map(category =>
                <Grid.Column key={category.id + category.slug}>
                  <Link
                    className={Number(this.props.match.params.categId) === category.id ? 'active' : ''}
                    to={`/category/${category.id}`}>{category.name}
                  </Link>
                </Grid.Column>
              )}
            </Grid>
          </Container>
        </div>

        {loading === 1 && products.length === 0 &&
        <div className='ProductsWrapper'>
          <Loader active/>
        </div>}

        {products.length === 0 && loading === 0 &&
        <div className='ProductsWrapper notFound'>
          <p>{`Nie znaleziono żadnych produktów dla wybranej kategorii`}</p>
        </div>}

        {products.length > 0 &&
        <InfiniteScroll
          dataLength={products.length}
          next={this.loadProducts}
          hasMore={hasMore}
        >
          <ProductsList
            products={products}
            title={this.getCategoryName(products[0].categories)}
          />
        </InfiniteScroll>
        }
      </div>
    );
  }
}

Products.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(productPropType).isRequired,
  hasMore: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      categId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  loading: getProductsFetching(state.products),
  categories: getCategories(state.categories),
  products: getProducts(state.products, props.match.params.categId),
  hasMore: getProductsHasMore(state.products),
  searchVisible: isSearchVisible(state.navbar),
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ fetchProducts, closeSearch, fetchCategories }, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products);
