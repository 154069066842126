import 'whatwg-fetch';
import config from '../../config/config';
import store from "../../configureStore";

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';

export const requestProducts = () => ({
  type: REQUEST_PRODUCTS,
});

export const receiveProducts = products => ({
  type: RECEIVE_PRODUCTS,
  products,
});

export const fetchProducts = (params = {}, skip = false) => (dispatch) => {
  const { products } = store.getState();

  if (products.items.length === 0 || params.page > 1 || products.items.length === 1 || skip) {
    dispatch(requestProducts());
    let url;

    if (params && params.id) {
      url = config.API_PRODUCT_URL + String(params.id);
    } else {
      url =
        config.API_PRODUCTS_URL +
        '?' +
        Object.keys(params)
          .map(k => k + '=' + encodeURIComponent(params[k]))
          .join('&');
    }

    return fetch(url)
      .then(response => response.json())
      .then(json => dispatch(receiveProducts(json)))
      .catch(() => {
        dispatch(receiveProducts([]));
      });
  }
};
