import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {Table, Dropdown, TextArea, Form } from 'semantic-ui-react';

import './Attributes.scss';

function mapStateToProps(state) {
  return {};
}

class Attributes extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  handleChange(event, value, name) {
    this.props.sendAttributes(name, value);
  }

  render() {
    return (
      <div>
        <Table className='AttributesTable'>
          <Table.Body>
            {this.props.attributes.map(attribute =>
              <Table key={attribute.name + attribute.id}>
                <Table.Cell className='AttributesCellKey'>
                  {attribute.name}
                </Table.Cell>
                <Table.Cell className='AttributesCellValue'>
                  <Dropdown
                    onChange={(event, data) => this.handleChange(event, data.value, attribute.name)}
                    fluid
                    icon='chevron down'
                    placeholder={`${attribute.name}...`}
                    options={attribute.options.map(option => ({key: option, value: option, text: option}))}
                  />
                </Table.Cell>
              </Table>
            )}
            <Table>
              <Table.Cell className='AttributesCellPersonalize'>
                <span>peronalizacja</span>
              </Table.Cell>
            </Table>
          </Table.Body>
        </Table>
        <Form className='AttributesTextarea'>
          <TextArea
            onChange={(event, data) => this.handleChange(event, data.value, 'Personalizacja')}
            placeholder='Coś specjalnego? Napisz :)'
          />
        </Form>
      </div>
    );
  }
}

Attributes.propTypes = {
  sendAttributes: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
)(Attributes);
