import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader, Container } from 'semantic-ui-react';
import { fetchCategories } from '../Categories/actions';
import { fetchPosts } from '../Blog/actions';
import { getCategories, getCategoriesFetching } from '../Categories/reducer';
import { getPosts, getPostsFetching } from '../Blog/reducer';
import LatestPosts from '../../components/LatestPosts';
import CategoriesList from '../../views/Categories/CategoriesList';
import SectionHeader from '../../components/SectionHeader';
import ContactSegment from '../../components/ContactSegment';
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';
import './Home.scss';
import about from './home.png';

class Home extends Component {
  constructor(props) {
    super(props);
    this.loadCategories = this.loadCategories.bind(this);
    this.loadPosts = this.loadPosts.bind(this);
  }

  componentDidMount() {
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }

    this.readCategories(1);
    this.readPosts(1);
  }


  loadCategories() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating categories
      const nextPage = Math.round(this.props.categories.length / 20) + 1;
      this.readCategories(nextPage);
    }
  }

  readCategories(page) {
    const { dispatch } = this.props;
    dispatch(fetchCategories({ page, per_page: 20, hide_empty: true }));
  }

  loadPosts() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating posts
      const nextPage = Math.round(this.props.posts.length / 20) + 1;
      this.readPosts(nextPage);
    }
  }

  readPosts(page) {
    const { dispatch } = this.props;
    dispatch(fetchPosts({ page, per_page: 20, hide_empty: true }));
  }

  render() {
    const { loadingPosts, loadingCategories, categories, posts } = this.props;
    return (
      <div>
        <div style={{minHeight: 600}}>
          <SectionHeader
            img={about}
            rtl
            text='Znalazłeś się w niesamowitym świecie skórzanego rzemiosła, gdzie wysoka jakość, precyzja wykonania oraz skóry z najlepszych europejskich garbarni, łączą się w produkty piękne i ponadczasowe, wykonane w całości ręcznie, zgodnie z tradycyjnymi metodami rzemieślniczymi. Stwórzmy razem produkt skrojony specjalnie dla Ciebie, zasługujesz na to.'
          />
          <Container>
            <div className='CategoriesWrapper'>
              {loadingCategories ? <Loader active /> : <CategoriesList categories={categories.slice(0, 3)}/>}
              <Link to='/categories'>zobacz wszystko</Link>
            </div>
            <div className='PostsWrapper'>
              {loadingPosts ? <Loader active /> : <LatestPosts posts={posts.slice(0, 3)}/>}
            </div>
          </Container>
        </div>
        <ContactSegment/>
      </div>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loadingCategories: getCategoriesFetching(state.categories),
  loadingPosts: getPostsFetching(state.posts),
  categories: getCategories(state.categories),
  posts: getPosts(state.posts),
  searchVisible: isSearchVisible(state.navbar)
});

function mapDispatchToProps(dispatch) {
  return Object.assign({ dispatch }, bindActionCreators({ fetchCategories, fetchPosts, closeSearch }, dispatch));
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
