const config = {
  SHOP_NAME: 'Pipala',
  API_CATEGORIES_URL: 'https://pipala.pl/wp-json/pwa/categories/',
  API_PRODUCTS_URL: 'https://pipala.pl/wp-json/pwa/products',
  API_PRODUCT_URL: 'https://pipala.pl/wp-json/pwa/product/',
  API_VARIATIONS_URL: 'https://pipala.pl/wp-json/pwa/product-variations/',
  API_VOUCHER_CODE_URL: 'https://pipala.pl/wp-json/pwa/voucher/',
  API_CHECKOUT_URL: 'https://pipala.pl/wp-json/pwa/proceed-checkout',
  API_POSTS_URL: 'https://pipala.pl/wp-json/pwa/blog',
  API_POST_URL: 'https://pipala.pl/wp-json/pwa/post/',
  API_STRIPE_SESSION_URL: 'https://pipala.pl/wp-json/pwa/stripe-session',
  API_CREATE_ORDER: 'https://pipala.pl/wp-json/pwa/create-order',
  API_UPDATE_ORDER: 'https://pipala.pl/wp-json/pwa/update-order',
  API_SHIPPING_METHODS_URL: 'https://pipala.pl/wp-json/pwa/shipping-methods',
  CHECKOUT_URL: 'https://pipala.pl/checkout',
  CURRENCY: 'zł',
  OFFLINE: false,
};

export default config;
