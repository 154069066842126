import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

import './CategoryCard.scss';
import LazyImage from "../../components/LazyImage";

class CategoryCard extends Component {
  render() {
    return (
      <Card className='CategoryCard'>
        <Link to={'/category/' + this.props.id}>
          <LazyImage src={this.props.src}/>
          <p>{this.props.name}</p>
        </Link>
      </Card>
    );
  }
}

CategoryCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string,
};

CategoryCard.defaultProps = {
  src: '',
};

export default CategoryCard;
