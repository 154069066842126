import React from 'react';
import {Card, Segment, Icon} from "semantic-ui-react";
import _ from "lodash";
import {Link} from "react-router-dom";

import './LatestPosts.scss';
import LazyImage from "../LazyImage";

const LatestPosts = props => {
  if (_.isNil(props.posts) || _.isEmpty(props.posts)) {
    return <div className='LatestPostsError'> Nie ma innych postów :( </div>;
  }
  const list = props.posts.filter(post => post.categories[0] !== 54).map(element => (
    <Card className='LatestPostCard' key={element.id + element.name}>
      <Link to={'/post/' + element.id}>
        <div className='LatestPostImage'>
          <LazyImage type='background' src={element.imgThumb}/>
        </div>
        <p>{element.name}</p>
        <span className='ReadMore'>czytaj<Icon name='arrow right'/></span>
      </Link>
    </Card>
  ));

  return (
    <Segment basic className='LatestPosts'>
      <Card.Group itemsPerRow={3} stackable>
        {list}
      </Card.Group>
    </Segment>
  );
};

export default LatestPosts;
