import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import store from './configureStore';
import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
import Products from './views/Products';
import Categories from './views/Categories';
import Product from './views/Product';
import Cart from './views/Cart';
import Search from './views/Search';
import Blog from './views/Blog';
import BlogPost from './views/BlogPost';
import About from './views/About';
import Contact from './views/Contact';
import Checkout from './views/Checkout';
import Success from './views/Checkout/Success';
import ScrollToTop from './ScrollToTop';

import './index.css';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <div className='App'>
        <NavBar />
        <div className='App-content'>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 2 }}
            atActive={{ opacity: 1 }}
            mapStyles={styles => {
              if(styles.opacity > 1){
                return { display: 'none'}
              }
              return { opacity: styles.opacity, height: '100%'}
            }}
            className="switch-wrapper"
          >
            <Route exact path="/" component={Home} />
            <Route path="/categories" component={Categories} />
            <Route path="/category/:categId" component={Products} />
            <Route path="/product/:productId" component={Product} />
            <Route path="/search/:search" component={Search} />
            <Route path="/cart" component={Cart} />
            <Route path="/blog" component={Blog} />
            <Route path="/post/:postId" component={BlogPost} />
            <Route path="/about" component={About} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/success/:sessionId/:orderId" component={Success} />
            <Route path="/contact" component={Contact} />
          </AnimatedSwitch>
        </div>
        <Footer/>
      </div>

      {/*</App>*/}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
